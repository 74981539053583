<template>
    <div class="flex justify-content-between mb-4 align-items-center">
        <div class="block">
            <div class="text-2xl uppercase">
                {{headline}}
            </div>
        </div>
        <div class="block">
            <router-link :to="buttonPath" v-if="buttonPath && buttonText">
                <Button
                    :label="buttonText"
                    class="p-button-secondary p-button-sm"
                />
            </router-link>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button/Button";
    export default {
        components: {
            Button
        },
        props: {
            headline: {
                type: String,
                default: ''
            },
            buttonText: {
                type: String,
                required: false
            },
            buttonPath: {
                type: String,
                required: false
            }
        }
    }
</script>
