export default {
  methods: {
    loadEntity(entity = null) {
      if(entity) {
        this.$getFromApi(this.getApiEndpoint(), entity.id, (entity) => {
          this.entity = entity;
          this.editedEntity = Object.assign({}, this.entity);
          this.editedEntityErrors = {};
        });
      } else {
        if (this.isRouteEntityGuesserDisabled() || !this.$route.params.hasOwnProperty('id') || this.$route.params.id === 'create') {
          this.entity = this.getCreateObject();
          this.editedEntity = Object.assign({}, this.entity);
          this.editedEntityErrors = {};
          this.loadSuccessFullAction();
        } else {
          this.$getFromApi(this.getApiEndpoint(), this.$route.params.id, (entity) => {
            this.entity = entity;
            this.editedEntity = Object.assign({}, this.entity);
            this.editedEntityErrors = {};
          });
        }
      }
    },
    isRouteEntityGuesserDisabled: () => false,
    getCreateObject() {
        return {};
    },
    loadSuccessFullAction() {
      return;
    },
    getCreationAction() {
      this.getUpdateAction();
    },
    submitEntity() {
      this.editedEntityErrors = {};

      this.$sendToApi(
        this.getApiEndpoint(),
        this.editedEntity,
        (entity, status, errors) => {
          console.log(status);
          if (status < 300) {
            if (status === 201) {
              this.getCreationAction(entity);
            } else {
              this.getUpdateAction(entity);
            }
            this.loadEntity();
          } else {
            this.editedEntityErrors = errors;
          }
        })
      ;
    }
  },
  watch: {
    '$route': function () {
      this.loadEntity();
    }
  },
  mounted() {
    this.loadEntity();
  },
  data() {
    return {
      entity: {},
      editedEntity: {},
      editedEntityErrors: {},
    }
  }
}
